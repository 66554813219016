const baseApiUrl = 'https://api-staging.casa-doc.it/';
const apiUrl = `${baseApiUrl}api/`;
const graphQlUrl = '';

export const environment = {
  production: false,
  name: 'staging',
  appName: 'casa-doc',
  storageVersion: '1.0.0',
  apiUrl,
  graphQlUrl,
  authInterceptorUrls: [apiUrl, graphQlUrl],
  mockApiUrl: 'https://localhost:3002/',
  logUrl: '',
  sockets: { url: '' },
  httpRetryConfig: {
    enabled: true,
    verbs: {
      get: { enabled: true, timeout: 6000, maxRetries: 2, retryForStatusesCodes: [500] },
      post: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      patch: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      put: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      delete: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
    },
  },
  graphQlConfig: {
    enabled: true,
    requestTimeout: 5000,
  },
  loggerConfig: {
    enabled: true,
    maxLogRentention: 20,
    logHttpErrorsWithStatusesCodes: ['0', '4XX', '5XX'],
    logDecoratedExceptions: true,
    logExceptions: true,
    saveLogMessagesInterval: 30000,
    logUrl: '',
    sendLogs: false,
    sendRequestBody: false,
    sendQueryParams: false,
  },
  tagManager: {
    enabled: false,
    apiKey: '',
  },
  registerServiceWorker: false,
};
